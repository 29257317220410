<template>
  <div class="about">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title" id="title">Services Details</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <!-- <a href="index.php">Home </a> -->
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <router-link to="/services">Services</router-link>
                    <!-- <a href="services.php">Services</a> -->
                  </li>
                </ol>
              </nav>
            </div>
            <!-- page title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->

    <!--====== SERVICES OPTIMIZATION PART START ======-->

    <div class="services-optimization-area pt-80 pb-120">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-lg-1 order-2">
            <div class="services-optimization-thumb mt-40">
              <img id="image1" src="assets/images/services-op-1.jpg" alt />
            </div>
            <!-- <div class="services-optimization-thumb-2">
              <img id="image2" src="assets/images/services-op-2.jpg" alt />
            </div> -->
          </div>
          <div class="col-lg-6 order-lg-2 order-1">
            <div class="services-optimization-content mt-40">
              <h3 class="title" id="title2">Nothing to do anything.</h3>
              <p class="description" id="description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--====== SERVICES OPTIMIZATION PART ENDS ======-->
  </div>
</template>

<script>
import $ from "jquery";

export default {
  components: {},
  props: {},
  data() {
    return {
      hire: null,
      url_data: null
    };
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0,0);
    this.fillupdata();
  },
  watch: { 
     '$route.params': {
        handler:async function() {
          //  console.log("search",params)
            await this.fillupdata();
          //  console.log("search22",params)
        },
        deep: true,
        immediate: true
      }
},
  methods: {
    fillupdata() {
          this.url_data = this.$route.query.name;
    // console.log("this.url_data", this.url_data);
    
    var service = {
        Mobile_App_Development: {
          title: "Mobile App Development",
          title2: "Mobile App Development",
          image1: "assets/images/mobiledeveloper.png",
          image2: "./assets/images/mobile.png",
          description:
            "The goal of mobile app development services is to create an android app and also the most fascinating iOS applications that complement or replace web solutions. We offer advisory or practical help to clients regarding app development whether they haven't started, are mid-way through, or have problems with an existing app. Kibbutz provides end-to-end application design, integration, and administration solutions. The company leads the full mobile app development process from inspiration and concept through delivery and continuous support, whether it's a consumer-oriented app or a disruptive enterprise-class solution. With over a decade of experience in developing mobile apps, our developers create solutions that help businesses meet market requirements, strengthen their brand identity and enable growth and expansion. With a significant number of users shifting to a mobile-centric internet, our mobile application development services are suitable for all your business requirements, which are replicated from your web application to the free window. This can assist you in increasing your ROI by increasing customer satisfaction."
        },
        Web_App_Development: {
          title: "Web Development",
          title2: "Web Development",
          image1: "assets/images/webdeveloper2.png",
          image2: "./assets/images/webdeveloper.png",
          description:
            "Web development services aid in the creation of all types of web-based software and ensure that web users have a positive experience. We are a company that specialises in website design and development. The experiences we create are high-performing, feature-packed, and digitally transformative, designed to be user-friendly, fully functional, very secure, and scalable for your enterprise's growth. Our web developers offer our clients skilled web application development and web design services. We provide a wide range of website design and development services, from mobile web development and responsive website designs to custom e-commerce and intranet experiences built with the most up-to-date and proven web technologies. The company is constantly innovating in order to provide highly compatible and efficient web development stacks. The importance of your services today motivates our developers to create highly professional techniques for crafting web applications."
        },
        Mobile_Game_Development: {
          title: "Mobile Game Development",
          title2: "Mobile Game Development",
          image1: "assets/images/game.png",
          image2: "./assets/images/game2.png",
          description:
            "Mobile game development is the process of creating a game from scratch for mobile platforms. Planning, production, testing, and launch are all stages in the development of a mobile game. Our company is one of the most mature ones in India when it comes to comprehensive game development solutions that include planning, designing, developing, and marketing your games. The company has a track record of providing low-cost game design and development solutions. Precision can be a problem in several industries, which is why we, our developers, work hand-in-hand with designers and customers to develop games. With the help of a diverse range of PC console, mobile, and online platform games for our clients' various needs, we've reached the summit of the game creation industry. Our business has a track record of developing 2-D and 3-D games that can be seamlessly integrated into VR. The dedicated developers are well-versed in all of the latest tools and are familiar with all of the industry's major game production platforms."
        },
        UI_UX_Design: {
          title: "UI/UX Design",
          title2: "UI/UX Design",
          image1: "assets/images/uiux.png",
          image2: "./assets/images/uiux2.png",
          description:
            "We're a group of well-known, award-winning web and graphics designers who believe in translating their ideas into images. The web designers in this department are known for using good functionality and creative isualisations to provide fluent usability. Our team (UI designers and UX designers) helps people get the most out of your product by improving their experience and ensuring they see the value you provide. The organisation is focused on learning everything there is to know about the client and conductin in-depth research in order to design a `grand brand` solution to target your valued audience. Kibbutz believes that user engagement happens the best when UI & UX work together. Our UX developers are dedicated to creating intriguing design concepts that are scalable and have a wide range of variations for all types of edits and additions to your brand solution. Our year of experience has enabled us to develop an efficient design process that provides solid results to our clients more quickly. We adhere to established design standards, workflows, and rules, ensuring that you receive the product you require, delivered on time by competent designers."
        },
          Digital_Marketing: {
          title: "Digital Marketing",
          title2: "Digital Marketing",
          image1: "assets/images/marketing.png",
          image2: "./assets/images/marketing3.png",
          description:
            "We are a dynamic, adaptable, full-service digital marketing agency that does not rely on gimmicks to attract new clients. Instead, we rely on our own search engine optimization (SEO) and marketing expertise to attract new visitors to the website. We collaborate with you to provide personalised internet marketing services and a strategy that meets your specific business demands. Start leveraging the power of digital marketing right now to generate the kind of results that will allow you to expand into new markets, recruit more employees, and achieve incredible success in your field. We, as a digital marketing agency, generally evaluate your website traffic, determine the best online platforms to invest your money in, and maintain a stable relationship between your marketing activities and their results. Our digital marketing team collaborates to develop and implement a multi-faceted strategy for generating more leads and converting them into consumers. Our digital marketers will, on the whole, listen, build, launch, and grow your online presence."
        }
      };
      // console.log("service", service);
      var item = service[this.url_data];
      // console.log("item...", item);
      // console.log("item...", item.title);
      $(document).ready(function() {
          $("#title").text(item.title);
          $("#title2").text(item.title2);
          $("#image1").attr("src", item.image1);
          $("#image2").attr("src", item.image2);
          $("#description").text(item.description);
      });
    }
  }
};
</script>



 